import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import ExpandDetail from './Utils.js'; 
import FadeIn from 'react-fade-in';

import book1 from './img/atomic_habits.jpg'
import book2 from './img/deep_work.jpg'
import book3 from './img/why_we_sleep.jpg'
import shoe_dog from './img/shoe_dog.jpg'
import no_rules_rules from './img/no_rules_rules.jpg'
import black_box_thinking from './img/black_box_thinking.jpeg'
import book7 from './img/digital_minimalism.jpg'
import book8 from './img/youre_not_listening.jpg'
import book9 from './img/win_friends.jpg'
import cant_hurt_me from './img/cant_hurt_me.jpg'
import mans_search from './img/man_search_for_meaning.jpeg'
import siddhartha from './img/siddhartha.jpeg'

function Experience() {

  const exp_x = 
  <Col className="text-center">
      <p>Core energy trading.</p>
  </Col>

  const exp_one = 
  <Row className="text-left">
    <ul>
      <li>
        <p>
          Key stakeholder at managing and maintaining in-house quantitative libraries/algorithms. Regularly maintained and developed new features, mentored junior members, and acted as first line of contact for technical queries or information regarding team codebase.
        </p>
      </li>
      <li>
        <p>
          Responsibilities include translating risk model methodologies into code, performing historical backtests of new products for risk governance committees, executing service release testing, as well as producing ad-hoc what-if scenario analyses for senior management to drive strategic initiatives.
        </p> 
      </li>
      <li>
        <p>
         Major contributor in the development of an internal business RESTful API. The API enables ease of data distribution across the company and powers an interactive analytics dashboard capable of visualising complex portfolio profile drilldowns and historical time series data.
        </p>
      </li>
      <li>      
        <p>
          One of few key leads for designing and developing a Python-based ETL data framework, effectively streamlining data workflows, accelerating test cycles and providing ease-of-access for users with features such as data sourcing, parsing, manipulation and visualisation.
          </p>
      </li>
      <li>      
        <p>Experience working with large datasets, low level algorithms and memory optimisation.</p>
      </li>
      <li>
      <p>
        Drove projects to implement test driven development and packaging for distribution of codebase to the wider team, whilst providing heavy emphasis on documentation to maintain and control code quality.
        </p>
      </li>
    </ul>
  </Row>

const exp_two =
  <Row className="text-left">
    <ul>
      <li>
      <p>Led automation initiative on manual processes of various teams by transforming legacy Excel VBA code into Python scripts. The result hugely minimised operational risks, improved productivity and efficiency of teams, with process times cut down from hours to seconds.</p>
      </li>
      <li>
      <p>Developed various data extraction scripts for product and sales teams to bypass conventional requests to offshore teams, effectively speeding up analyses and fulfilling user’s immediate data requirements.</p>
      </li>
      <li>
      <p>Hosted division-wide coding tutorial sessions to upskill and integrate the use of programming in the workspace across London and New York risk teams, at the request of senior management.</p>
      </li>
    </ul>
  </Row>


const exp_three =
  <Row className="text-left">
    <ul>
      <li>
      <p>Client facing role involving regular liaison with fixed income trading desks of investment banks to provide monthly/quarterly volume reviews and market commentary. Communicated latest updates on system upgrades as well as regulatory developments.</p>
      </li>
      <li>
      <p>Conducted various data analyses to assist the sales team with creating new unique selling points when on-boarding new members as well as presenting new tradable products.</p>
      </li>
    </ul>
  </Row>

const exp_four = 
  <Row className="text-left">
    <ul>
    <li>  <p>Attended talks and training sessions around the ‘Trade Life Cycle’, liquidity management,
  post-trade processes and the importance of operational efficiency in investment banking.</p></li>
  <li>
  <p>Winning team for presentation on financial ESMA market directive/regulation “MiFID II/MiFIR”.</p>
  </li>
    </ul>
  </Row>

  return (
    <Container id="experience" className="pb-3">
      <div className="pt-5">
        <FadeIn delay="100">
          <h2><b>Professional Experience</b></h2>
          <p className="pt-4 pb-5"><i>"The man who moves a mountain begins by carrying away small stones." - Confucius</i>
          </p>          
        </FadeIn>
      </div>

      <Row className="justify-content-center">
        <Col>

        <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
              <h3>Software Engineer</h3>
              <br></br>
              <p><a href="https://www.dare.global/" target="_blank" rel="noopener noreferrer">Dare</a></p>
              <p>September 2022 - Present</p>
            </div>
            <ExpandDetail detail={exp_x}/>
          </FadeIn>

          <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
              <h3>Quantitative Developer</h3>
              <br></br>
              <p><a href="https://www.lch.com/services/swapclear" target="_blank" rel="noopener noreferrer">London Stock Exchange Group - LCH, SwapClear</a></p>
              <p>September 2019 - August 2022</p>
            </div>
            <ExpandDetail detail={exp_one}/>
          </FadeIn>

          <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
              <h3>Python Developer</h3>
              <br></br>
              <p><a href="https://www.lch.com/services/swapclear" target="_blank" rel="noopener noreferrer">London Stock Exchange Group - LCH, SwapClear</a></p>
              <p>September 2018 - September 2019</p>
            </div>
            <ExpandDetail detail={exp_two}/>
          </FadeIn>

          <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
                <h3>Summer Intern</h3>
                <br></br>
                <p><a href="https://www.mtsmarkets.com/" target="_blank" rel="noopener noreferrer">London Stock Exchange Group - MTS</a></p>
                <p>June 2017 - September 2017</p>
              </div>
            <ExpandDetail detail={exp_three}/>
          </FadeIn>

          <FadeIn delay="400">
          <hr></hr>
          <div className="pt-4 pb-4">
              <h3>Spring Intern</h3>
              <br></br>
              <p><a href="https://www.goldmansachs.com/" target="_blank" rel="noopener noreferrer">Goldman Sachs</a></p>
              <p>April 2016</p>
          </div>
          <ExpandDetail detail={exp_four}/>
          </FadeIn>

        </Col>
      </Row>
    </Container>
  )
}

function Projects() {

  const exp_one =
  <Row className="text-left">
    <ul>
      <li>
      <p>
    Minigamegift is a digital and eco-friendly alternative to traditional paper card gifts, 
    allowing you to create a short personalised game for friends and loved ones.
    </p>
      </li>
      <li>
      <p>
    Customers can choose from a selection of games and start by personalising the player character, 
    as well as include custom messages that appear in-game for their recipient.</p>
      </li>
      <li>
      <p>
    Quick and easy process from customisation to payment, and playable immediately 
    through a web browser, both on mobile (android/iOS supported) or desktop devices. 
    </p>
      </li>
      <li>
      <p>
    Greatly surpassed sales expectations at launch, and achieved a large following through social media platforms. 
    All feedback received from customers is highly positive with an average rating of 4.5/5
    </p>
      </li>
    </ul>
  </Row>

const exp_two =
  <Col className="text-center">
      <p>React based application developed, designed and deployed to showcase a 
         digital alternative of my CV.</p>
  </Col>


const exp_three = 
  <Col className="text-center">
  <a href="https://github.com/dencangan/" target="_blank" rel="noreferrer"><p>https://github.com/dencangan/</p></a>
  </Col>

  return (
    <Container id="experience" className="pb-3">
      <div className="pt-5">
        <FadeIn delay="100">
        <h2><b>Personal Projects</b></h2><i>
        <p className="pt-4 pb-5">
          "Things that are easy to do are also easy not to." - Jim Rohn
          </p></i>   
        </FadeIn>
      </div>
      
      <Row className="justify-content-center">
        <Col>
          {/* <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
                <h3><a href="https://www.minigamegift.com/" target="_blank" rel="noopener noreferrer">minigamegift</a></h3>
                <br></br>
                <p>Personalised video games for friends and loved ones.</p>
            </div>
            <ExpandDetail detail={exp_one}/>
          </FadeIn> */}

          <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
              <h3><a href="/portfolio">Portfolio Website</a></h3>
              <br></br>
              <p>You're looking at it!</p>
            </div>
            <ExpandDetail detail={exp_two}/>
          </FadeIn>

          <FadeIn delay="400">
            <hr></hr>
            <div className="pt-4 pb-4">
                <h3><a href="https://github.com/dencangan" target="_blank" rel="noopener noreferrer">Github</a></h3>
                <br></br>
                <p>Random side projects to share.</p>
            </div>
            <ExpandDetail detail={exp_three}/>
          </FadeIn>
        </Col>
      </Row>
    </Container>
  )
}

function Books() {

  return (
    <Container id="experience" className="pb-3">
      <div className="pt-5">
        <FadeIn delay="100">
          <h2><b>Favourite Books</b></h2>
          <p className="pt-4 pb-5">
            "Books are the quietest and most constant of friends; they are the most accessible and wisest 
            of counselors, and the most patient of teachers."<br></br> - Charles W. Eliot
            <br></br><br></br>
            Just some books I really enjoyed...!
          </p>   

        </FadeIn>
      </div>
      <hr></hr>

      <Row className="justify-content-center">
        <FadeIn delay="900">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={mans_search} alt="book4"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="1100">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={siddhartha} alt="book5"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="1300">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={cant_hurt_me}alt="book6" ></img>
              </div>
          </Col>
          </FadeIn>
      </Row>

      <hr></hr>
      
      <Row className="justify-content-center">
        <FadeIn delay="400">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={book1} alt="book1" ></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="500">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={book2} alt="book2"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="700">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={book3} alt="book3"></img>
              </div>
          </Col>
          </FadeIn>
      </Row>

      <hr></hr>

      <Row className="justify-content-center">
        <FadeIn delay="1500">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={black_box_thinking} alt="book7"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="1700">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={shoe_dog} alt="book8"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="1900">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={no_rules_rules} alt="book9"></img>
              </div>
          </Col>
          </FadeIn>
      </Row>

      <hr></hr>

      <Row className="justify-content-center">
        <FadeIn delay="1500">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={book7} alt="book7"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="1700">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={book8} alt="book8"></img>
              </div>
          </Col>
          </FadeIn>

          <FadeIn delay="1900">
          <Col>
              <div className="pt-4 pb-4 pl-4 pr-4">
                <img width="200" height="300" src={book9} alt="book9"></img>
              </div>
          </Col>
          </FadeIn>
      </Row>

    </Container>
  )
}

function Articles() {
  return (
      <Container className="pb-3 pt-4">
        <FadeIn delay="100">
        <h2><b>Articles</b></h2>
        <p className="pt-4 pb-5">"Writing is thinking. To write well is to think clearly." - David McCullough</p>
        </FadeIn>

        <FadeIn delay="400">
          <Col className="pt-4 text-left">
            <ul>
                <li>
                  <Link to="/article_try_except">How to use Python's try/except clause.</Link>
                </li>
            </ul>
          </Col>
        </FadeIn>
      </Container>
    )

}


export {Experience, Projects, Books, Articles};
