import article_try_except from './articles.js'
import {Experience, Projects, Books, Articles} from './sections.js'; 
import avatar from './img/avatar.png'
import React from 'react';
import { Route, Switch, BrowserRouter, Link } from 'react-router-dom'
import {Col, Row, Container, Modal, Nav } from 'react-bootstrap';
import FadeIn from 'react-fade-in'
import './App.css'

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
      <BrowserRouter>
        <div className="App">
        <ContactModal show={modalShow} onHide={() => setModalShow(false)}/>
          <Container className="main_banner" >
            <h1><a href="/">Dencan Gan</a></h1>
          </Container>
          <div id="site">
            <div id="canvas">
                <Container>
                <nav className="navbar-light border-bottom border-secondary">
                <button id="navButton" className ="navbar-toggler" type ="button" 
                   data-toggle ="collapse" data-target ="#colNav">   
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="none" stroke="rgb(69, 68, 67)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    </span>
                  </button>
                  <div class ="border-top border-secondary collapse navbar-collapse" id ="colNav">
                    <Nav className ="navbar-nav" defaultActiveKey="/">
                        <Link className="nav-link" to="/experience">Experience</Link>
                        <Link className="nav-link" to="/projects">Projects</Link>
                        {/* <Link className="nav-link" to="/articles">Articles</Link>                       */}
                        {/* <Link className="nav-link" to="/books">Favourite Books</Link> */}
                        <Link className="nav-link " onClick={() => setModalShow(true)}>Contact</Link>
                    </Nav>  
                  </div>               
                  </nav>
                </Container>
              <Switch>
                <Route exact path="/" component={About} />
                <Route path="/experience" component={Experience} />
                <Route path="/projects" component={Projects} />
                {/* <Route path="/books" component={Books} /> */}
                {/* <Route path="/articles" component={Articles} /> */}
                {/* <Route exact path="/article_try_except" render={ () => (article_try_except)}/> */}
              </Switch>
              <footer className="footer border-top border-secondary">
                <div className="container">
                  <span className="text-muted">
                    <p>Copyright &copy; 
                      <a href="/"> Dencan Gan 2020</a>
                    </p>
                    </span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </BrowserRouter>

  );
}

function About() {
  return (
      <Container>
        <FadeIn delay="400">
          <Col>
          <br></br>
            <h1>Hi there!</h1>
          </Col>
        </FadeIn>
        <Col>
        
        <img src={avatar} alt="back portrait"></img>
        {/* <p>
        <br></br>
          "A ship in harbor is safe, but that is not what ships are built for." - William Shedd
        </p> */}
        </Col>

        <Row>
          <Col>
            <p>Welcome to my portfolio website.</p>
            <p>I am a programmer currently based in London with strong interests in coding, 
              technology, and business. In this website, you'll find details about my experience and projects. 
            </p>
              <p>
              Click on 
              <button id="navButton" className ="navbar-toggler" type ="button" 
                   data-toggle ="collapse" data-target ="#colNav">   
              <span>
              <svg id="icon_space" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" fill="none" stroke="rgb(69, 68, 67)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
              </span></button>
              to discover more!<br></br>
              </p>
          </Col>

        </Row>


      </Container>
  )
}

function ContactModal(props) {
  /**
   * Render contact modal pop up.
   */
  return (
    <Modal {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <b>Contact</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Open to project collaborations, job opportunities, and etc.
        </p>
        <p>
        Connect with me via Linkedin or Github below. 
        If you'd like to have my contact details, drop me a message!
        </p>
        <hr></hr>
        <div class="mb-1">
          <a href="https://www.linkedin.com/in/dencangan/" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-linkedin mr-3">
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
              <rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle>
            </svg>
          </a>
          <a href="https://github.com/dencangan" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-github">
              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
              </svg>
          </a>
        </div>
      </Modal.Body>

    </Modal>
  );
}

export default App;
