import React from 'react';
import { Col } from 'react-bootstrap';


const article_try_except = <Col className="text-left">
      <br></br>
      <h2>How to use Python's try/except clause.</h2>
      <br></br>
      <p>
      Python try except clause is designed to handle exceptions or errors in a program. In this article, I will go through how to use them properly, but first, it is worth discussing how not to use them.
      <br></br><br></br>
      <code>
      try:
      <div class="tab"># do something</div>
      except:
      <div class="tab"># do something else if above fails</div>
      </code>

      <br></br>
      The implementation is not good. Why?<br></br><br></br>

      You hide the error from yourself. By leaving a generic except, all kinds of exceptions are accepted, and without any sense of verbosity, the underlying error is hidden from you. Sure, the except block of code gets executed, you know something went wrong, but you just don’t know what it was!
      <br></br><br></br>
      So, let’s add some verbosity, right? 
      <br></br><br></br>
      
      <code>
      try:
      <div class="tab"># do something</div>
      except Exception as e:
      <div class="tab"># catch the error and print it to the user</div>
      <div class="tab">print(e)</div>
      <div class="tab"># do something else</div>
      </code>

      <br></br>
      Is this any better? Not really. The Exception class is part of Python’s built-ins, so only Python exceptions are captured here. When using other packages, specific errors from the package will not be captured! It will crash, so the application of try/except here is effectively useless.
      <br></br><br></br>
      So how do we implement try/except properly in Python? If I’m writing a program for the first time, I wouldn’t know what kinds of errors will the program produce in the first place, right? It’s like the chicken and the egg question! What can we do?
      <br></br><br></br>
      We can follow a set of best practices when implementing try/except. Anticipation is the key here. Ask yourself, “I’ve written this line of code, but I’m unsure if it will behave correctly, I anticipate an error may occur here. After some thought, it is likely this <u>specific error</u> will be raised, so let me put on a try/except here.” 
      <br></br><br></br>
      The goal here isn’t accuracy at catching the right error. It is anticipation, as you have yet to run your code and test your hypothesis. But at least you now know there lies a part of your program that has the possibility of behaving incorrectly. Then, we tweak this as we start to understand the error better over time, evolving the line of code or the try/except clause to better optimise our program. 
      <br></br><br></br>
      When writing a try/except, you should always do one thing and one thing only in the try block. Then, catch one specific error with the except! Remember, the key here is that we are trying to be as specific as we can. The general rule is:
      <br></br><br></br>
      Do one thing -- Catch one error
      <br></br><br></br>

      <code>
      try:
      <div class="tab"># do one thing</div>
      except IndexError:
      <div class="tab"># do something when IndexError happens</div>
      </code>

      <br></br>
      But wait a minute, what if I’m anticipating other errors too? Just add more excepts to handle more specific errors! It will work the same way like the elif condition.
      <br></br><br></br>
      
      <code>
      try:
      <div class="tab"># do one thing</div>
      except IndexError:
      <div class="tab"># do something if IndexError happens</div>
      except KeyError:
      <div class="tab"># do something if KeyError happens</div>
      </code>

      <br></br>
      Python try/except is like a double edged sword. When used properly, it gives you the ability to anticipate potential errors, redirect your program and re-handle that line of code with an alternative solution. It also emphasises on the expected errors that specific line of code may produce, which in turn warns other developers when working on the same program, but more importantly challenges you to rethink about how you should rewrite that line. Generally, if you are very confident that your code should behave correctly, then you do not need a try/except clause. Of course, exceptions do exist (i.e. catching server connection errors).
      <br></br><br></br>
      When used incorrectly, it will hide bugs, create grey areas with vagueness, and will be very painful to debug. If this practice continues in the long term, you will see a deterioration of the program’s code quality, as the underlying bugs are left unaddressed, ultimately leading to a flimsy and unscalable program!
      <br></br><br></br>
      
      <b>Summary</b>
      <br></br>
      1. Python's try/except clause is meant to be specific, do not use it in a general sense.<br></br>
      2. Do one thing only in the try block, we are trying to anticipate the specific error this line of code may produce. It is called try for a reason! Adding more lines of code breaks this rule on what you want to catch exactly, and will be a nightmare when you are trying to trace that error.<br></br>
      3. You can catch multiple errors! Keep expanding on the except to anticipate more errors.
      </p>
  </Col>


export default article_try_except;
