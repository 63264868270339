import React from 'react';
import { Collapse, Col } from 'react-bootstrap';


class ExpandDetail extends React.Component {
  /**
   * The little box that expands to a downward arrow
   * @param {*State of expand or not} props 
   */ 
    constructor(props) {
      super(props);
      this.state = {
        isExpanded: false,
      }
    } 
    render() {
      return (
        <Col className="pb-4">
            <a onClick={()=>this.setState({ isExpanded: !this.state.isExpanded })}>
              <div>
                  { this.state.isExpanded
                      ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
                      : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" width="30" height="30" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                  }
              </div>
            </a> 
  
          <Collapse in={this.state.isExpanded}>
              <div>
                  <div className="pt-5">{this.props.detail}</div>
              </div>
            </Collapse>
        </Col>
      );
    }
  
  }

export default ExpandDetail;

